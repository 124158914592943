import { GetServerSideProps } from 'next'
import { getServerSession } from 'next-auth/next'
import { signOut } from 'next-auth/react'
import React from 'react'

import { useAuthContext } from '@/contexts/AuthContext'
import { LoginForm } from '@/features/auth/login'
import { withHead } from '@/hocs'
import { getAuthLayout } from '@/layouts'
import { authOptions } from '@/pages/api/auth/[...nextauth]'
import { clearToken, setToken } from '@/utils'

interface LoginProps {
  action?: string
}

const Login = ({ action }: LoginProps) => {
  const { login } = useAuthContext()

  const handleSubmit = React.useCallback(
    async (values, actions) => {
      try {
        await login(values)
      } catch (error) {
        actions.setErrors({ email: ' ', password: 'Invalid email or password' })
      }
    },
    [login],
  )

  React.useEffect(() => {
    if (!action) return

    if (action === 'logout') {
      signOut({ redirect: true, callbackUrl: `${window.location.origin}/auth/login` })
    }
  }, [action])

  return <LoginForm onSubmit={handleSubmit} />
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getServerSession(ctx.req, ctx.res, authOptions)

  const query = ctx.query

  if (query?.action === 'logout') {
    return { props: { action: 'logout' } }
  }

  if (!session) {
    await clearToken(ctx)

    return { props: {} }
  }

  // If there is no access token, redirect to signup flow
  if (!session?.accessToken) {
    return { redirect: { destination: '/auth/signup?activeStep=1', permanent: false } }
  }

  await setToken(ctx, session?.accessToken)

  return { redirect: { destination: '/feedback', permanent: false } }
}

Login.getLayout = getAuthLayout

export default withHead(Login, { title: 'Login' })
