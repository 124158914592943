import { signIn } from 'next-auth/react'
import { x } from '@xstyled/styled-components'

import { Text } from '@/kit'
import { Icons } from '@/assets'

import * as S from './GoogleLoginButton.styled'

interface GoogleLoginButtonProps {
  text?: string
  redirect?: boolean
  callbackUrl?: string
}

export const GoogleLoginButton = ({ text, redirect, callbackUrl }: GoogleLoginButtonProps) => {
  return (
    <x.div
      border="1px solid"
      borderColor={{ _: 'grey.16', hover: 'grey.48', focusWithin: 'purple.48' }}
      transition="border-color 0.14s"
      w={1}
      mb={20}
      h="36px"
      borderRadius="5px"
    >
      <S.$ButtonContainer
        onClick={() => {
          signIn('google', { callbackUrl, redirect })
        }}
        variant="tertiary"
        spaceX={10}
        maxWidth="unset"
        w={1}
      >
        <Icons.GoogleIcon width={16} height={16} />
        <Text fontWeight="semibold">{text ?? 'Log in with Google'}</Text>
      </S.$ButtonContainer>
    </x.div>
  )
}
