import styled from '@xstyled/styled-components'

import { Button } from '@/kit'

export const $ButtonContainer = styled(Button)`
  &&& {
    border: 1px solid ${'grey.48'} !important;
    background-color: ${'white'} !important;

    :focus-within {
      outline: none;
    }
  }
`
