import React from 'react'
import { x } from '@xstyled/styled-components'

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <x.div h="100vh" w="100vw" position="absolute" overflowY="auto" bg="white">
      {children}
    </x.div>
  )
}

export const getAuthLayout = (page: React.ReactNode) => <AuthLayout>{page}</AuthLayout>
