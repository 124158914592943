import * as Yup from 'yup'

export const validationSchema = Yup.object({
  email: Yup.string().email().required().default(''),
  password: Yup.string().required().default(''),
}).defined()
export type Values = Yup.Asserts<typeof validationSchema>
export const defaultValues = validationSchema.getDefaultFromShape()

export * as LoginSchema from './Login.schema'
