import styled from '@xstyled/styled-components'

export const $LoginFlowContainer = styled.divBox`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;

  height: 100vh;
  width: 100vw;
  background-color: ${'white'};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    padding: 10px;
  }
`

export const $LoginLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 10px;
  width: fill-available;
  height: fit-content;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 375px;
  margin: auto;

  form {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin: 80px auto;
    max-height: unset;
  }
`

export const $TopBar = styled.divBox`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  left: 20px;
  top: 20px;
`

export const $LoginRightContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    display: none;
  }
`

export const $MainImage = styled.imgBox`
  height: 100vh;
  object-fit: cover;
`
